import { useIntl } from 'react-intl';
import { logout } from '../../actions';
import { isBrowser } from '../../utils';
import BaseHOC from '../../components/BaseHOC';

const Logout = ({ dispatch, enqueueSnackbar }) => {
  if (!isBrowser) return null;
  const { formatMessage: f, locale } = useIntl();
  dispatch(logout(enqueueSnackbar, f, locale));
  return null;
};

export default BaseHOC(Logout);
